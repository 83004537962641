import { type CompanyData, type CompanyState, isEmpty, type UserId } from '@terros/common'

type CompanyStatePair = {
  value: CompanyState
  label: string
}

export const companyStates: CompanyStatePair[] = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'demo',
    label: 'Demo',
  },
  {
    value: 'suspended',
    label: 'Suspended',
  },
]

export function isAdmin(company?: CompanyData, userId?: UserId): boolean {
  if (isEmpty(userId)) return false
  if (!company || isEmpty(company.adminIds)) return false
  return company.adminIds.includes(userId)
}

// TODO compare with add
export function getLevelName(
  company: Pick<CompanyData, 'highestTeamLevel' | 'levelNames'> | undefined,
  level: number
): string {
  if (!company) {
    return 'Team'
  }
  const { highestTeamLevel = 0, levelNames = {} } = company
  if (highestTeamLevel < level) {
    return ''
  }
  return levelNames[level] ?? getLevelName(undefined, level)
}
